<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Erreur de récupération des données
      </h4>
      <div class="alert-body">
        Aucun utilisateur n'a été retrouvé sur cet ID. Vérifiez
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
        la liste
        </b-link>
        pour d'autres utilisateurs.
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Profil</span>
        </template>
        <user-edit-tab-account
          :user-data.sync="userData"
          class="mt-2 pt-75"
          @updateData="updateUser()"
        />
      </b-tab>

      <!-- Tab: Information -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab> -->
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "UserEdit",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    // UserEditTabInformation,
  },
  setup() {
      // Use toast
    const toast = useToast();

    const userData = ref(null)

    // const USER_APP_STORE_MODULE_NAME = 'app-user'

    // // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    const fetchUser = () => {
      store.dispatch('user/fetchUser', { id: router.currentRoute.params.id })
        .then(user => {
          userData.value = user
          userData.value.role_id = user.role.id
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    }

    const updateUser = () => {
      store
      .dispatch("user/updateUser", userData.value)
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "L'Utilisateur a été mise à jour avec succès.",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
        router.push({ name: "apps-users-list" });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Une erreur est survenue lors de la modification de l'utilisateur",
            icon: "AlertTriangleIcon",
            variant: "error",
          },
        });
      });
    }

    fetchUser()


    return {
      userData,
      updateUser,
    }
  },
}
</script>

<style>

</style>

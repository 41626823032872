import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "name",
      label: "Nom & Prénom",
    },
    { key: "email", sortable: true },
    {
      key: "role",
      label: "Rôle",
    },
    {
      key: "created_at",
      label: "Crée le",
      formatter: title,
      sortable: true,
    },
    {
      key: "is_active",
      label: "Statut",
      sortable: true,
    },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("created_at");
  const isSortDirDesc = ref(true);
  const roleOptions = ref([]);
  const roleFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchData();
  });

  // get all user's properties to create or edit a user
  const fetchUserProperties = (properties) => {
    let params = {
      props: properties,
    };
    store
      .dispatch("user/fetchProperties", params)
      .then((properties) => {
        roleOptions.value = properties.roles;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération des données (Roles)",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch("user/fetchUsers", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
      })
      .then((response) => {
        const { users, total } = response;

        callback(users);
        totalUsers.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération des utilisateurs",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const deleteUser = (user) => {
    alert('Impossible de supprimer un utilisateur dans votre système.')
    // store
    //   .dispatch("user/deleteUser", {id: user.uid})
    //   .then((response) => {
    //     toast({
    //       component: ToastNotificationContent,
    //       props: {
    //         title: "Erreur de récupération des utilisateurs",
    //         icon: "AlertTriangleIcon",
    //         variant: "danger",
    //       },
    //     });
    //   })
    //   .catch(() => {
    //     toast({
    //       component: ToastNotificationContent,
    //       props: {
    //         title: "Erreur de récupération des utilisateurs",
    //         icon: "AlertTriangleIcon",
    //         variant: "danger",
    //       },
    //     });
    //   });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "responsable_projet") return "primary";
    if (role === "comptable") return "warning";
    if (role === "archiviste") return "success";
    if (role === "secretaire") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "responsable_projet") return "UserIcon";
    if (role === "comptable") return "SettingsIcon";
    if (role === "archiviste") return "DatabaseIcon";
    if (role === "secretaire") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === false) return "warning";
    if (status === true) return "success";
    return "primary";
  };

  return {
    fetchUsers,
    fetchUserProperties,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    deleteUser,

    // Extra Filters
    roleFilter,
    roleOptions,
  };
}

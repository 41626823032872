<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          :variant="`light-${resolveUserRoleVariant(userData.role.name)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row class="mb-2">
        <!-- Field: Full Name -->
        <b-col cols="12" md="6">
          <b-form-group label="Nom & Prénom" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="6">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="3">
          <b-form-group label="Statut" label-for="user-status">
            <v-select
              v-model="userData.is_active"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="label"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col cols="12" md="3">
          <b-form-group label="Rôle" label-for="user-role">
            <v-select
              v-model="userData.role_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              label="name"
              :reduce="(val) => val.id"
              :clearable="false"
              input-id="user-role"
            >
              <template #no-options="{ searching, loading }">
                <span v-if="searching">Recherche en cours...</span>
                <span v-else-if="loading">Chargement en cours...</span>
                <span v-else>...</span>
              </template></v-select
            >
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <b-card no-body class="border mt-1">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon icon="LockIcon" size="18" />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table striped responsive class="mb-0" :items="permissionsData">
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$emit('updateData')"
    >
      Enregistrer
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.go(-1)"
    >
      Annuler
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, onMounted } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant, fetchUserProperties, roleOptions } =
      useUsersList();
    onMounted(() => {
      let props = ["roles"];
      fetchUserProperties(props);
    });

    const statusOptions = [
      { label: "Activé", value: true },
      { label: "Désactivé", value: false },
    ];

    const permissionsData = [
      {
        module: "Admin",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Staff",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatar = base64;
      }
    );

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
